<template>
  <section class="viewRecords">
    <component v-for="record in records" :key="record._id" :record="record" :is="componentIs"></component>
    <template v-if="isLoading">
      <CardLoading v-for="idx in 3" :key="idx" />
    </template>
    <button class="viewRecords__getMore scroll-bottom" @click="getMore">ver más</button>
  </section>
</template>

<script>
export default {
  components: {
    CardModelNew: () => import("../RecordsNews/CardModelNew.vue"),
    CardModelDisapproved: () => import("../RecordsDisapproved/CardModelDisapproved.vue"),
    CardLoading: () => import("./LoadingCard.vue"),
  },
  data() {
    return {
      cardByStatus: {
        new: "CardModelNew",
        disapproved: "CardModelDisapproved",
      },
    };
  },
  computed: {
    records() {
      return this.$store.state.records.recordsInFilter.map((record) => {
        record.isSelected = false;
        return record;
      });
    },
    componentIs() {
      const status = this.$route.params.status;
      return this.cardByStatus[status] || "";
    },
    isLoading() {
      return this.$store.state.records.isLoadingRecords;
    },
    queryString() {
      const { query, params } = this.$route;

      let queryString = `status=${params?.status || ""}&`;
      Object.entries(query).forEach(([key, val]) => (queryString += `${key}=${val}&`));

      return queryString;
    },
  },
  methods: {
    async getMore() {
      await this.$store.dispatch("records/getMoreRecords", this.queryString);
    },
  },
};
</script>

<style lang="scss">
.viewRecords {
  @include Flex(column);
  width: 100%;
  padding-bottom: 15px;
  gap: 15px;
  &__getMore {
    opacity: 0;
  }
}
</style>
